import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Button, ButtonTypes } from '../components/Button';
import { Layout } from "../components/Layout";
import SEOHeader from "../components/SEOHeader";

const AboutPage = () => {
    return (
        <Layout>
            <SEOHeader title="About" />
            <div className='flex flex-col w-full max-w-7xl mx-auto'>
                <Breadcrumbs
                    data={[{ title: "Home", url: "/" }, { title: "About Jess", url: "/about" }]}
                />
                <div className='flex w-full flex-col md:flex-row mb-4 gap-4 md:gap-4 p-4'>
                    <Transition
                        className='w-full max-w-lg mx-auto'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <StaticImage
                            src='../images/jess.jpg'
                            alt='Jess Fox sitting at a large audio mixer'
                            className='rounded-t-md w-full'
                        />
                        <div className='bg-black/80 text-white text-center p-2 rounded-b-md italic'>
                            <p>Operating the MixWizard WZ3</p>
                            <p>Photo by <a className='underline' href="https://lauraboswell.com" target="_blank">Laura Boswell</a></p>
                        </div>
                    </Transition>
                    <Transition
                        className='w-full grow space-y-3'
                        appear={true}
                        show={true}
                        enter="ease-out duration-500"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <p className='italic bg-emerald-900/20 p-4 rounded-md'>“Music may not be inviolable, but it is infinitely variable, acquiring a new identity in the mind of every new listener. It is always in the world, neither guilty nor innocent, subject to the ever-changing human landscape in which it moves.” – Alex Ross</p>
                        <h1 className='text-5xl font-ms-madi'>Hello, I'm Jess!</h1>
                        <p>I am a skilled and experienced sound engineer who works with digital and analogue workflows alike. I'm privileged to hold a number of local positions including:</p>
                        <ul className='list-disc ml-8'>
                            <li><span className='font-semibold'>Front of House Live Sound</span> at renowned downtown club <a className='font-semibold underline' href="https://thegreyeagle.com" target="_blank">The Grey Eagle</a></li>
                            <li><span className='font-semibold'>Crew member</span> at the women-owned-and-operated <a className='font-semibold underline' href="https://radhaus.studio" target="_blank">RadHaus Studios</a></li>
                            <li><span className='font-semibold'>Livestream engineer and assistant</span> to beloved folk extraordinaire <a className='font-semibold underline' href="https://davidlamotte.com" target="_blank">David LaMotte</a></li>
                        </ul>
                        <p>I operate consoles ranging from Allen & Heath to Yamaha to Midas, set up and mix for a variety of indoor and outdoor spaces, work with touring engineers, and sit in the director's chair.</p>
                        <p>I graduated from the <a className='underline' href="https://www.unca.edu/" target="_blank">University of North Carolina Asheville</a> with a degree in Music and Neuroscience. This, along with countless years of intentional listening and an assortment of music lessons and ensembles, has left me with an able ear and a deeper understanding of artist and audience.</p>
                        <p>With all the tools mentioned above, plus the inevitable constant of learning and growing, I’m capable of providing a multitude of creative services to support your needs. I’d love to talk about working on projects together, or we can just talk about music! 🎵</p>
                        <Button className='w-52' type={ButtonTypes.Link} onClick='/contact' filled={true} buttonText="Let's collaborate!" />
                    </Transition>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage;
